/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {SITE_MAP} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => (
  <Fragment>
    <SidebarMenuItem
      to={`/${SITE_MAP.ARTICLE_MANAGEMENT.INDEX}`}
      title='Quản lý bài đăng'
      icon='/media/biha/icons/svg/article.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.CATEGORY_MANAGEMENT.INDEX}`}
      title='Quản lý danh mục'
      icon='/media/biha/icons/svg/category.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.TAG_MANAGEMENT.INDEX}/${SITE_MAP.TAG_MANAGEMENT.LIST}`}
      title='Quản lý thẻ'
      icon='/media/biha/icons/svg/price-tag.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.BANNER_MANAGEMENT.INDEX}/${SITE_MAP.TAG_MANAGEMENT.LIST}`}
      title='Quản lý banner'
      icon='/media/biha/icons/svg/picture.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.BANNER_MANAGEMENT.INDEX}/${SITE_MAP.SUBSCRIBE_NEWSLETTER_MANAGEMENT.INDEX}/${SITE_MAP.SUBSCRIBE_NEWSLETTER_MANAGEMENT.LIST}`}
      title='Quản lý đăng ký nhận tin'
      icon='/media/biha/icons/svg/picture.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.BANNER_MANAGEMENT.INDEX}/${SITE_MAP.FAQ_MANAGEMENT.INDEX}/${SITE_MAP.FAQ_MANAGEMENT.LIST}`}
      title='Quản lý câu hỏi thường gặp'
      icon='/media/biha/icons/svg/picture.svg'
    />
  </Fragment>
)

export {SidebarMenuMain}
